import { useEffect, useRef, useState } from "react";
import type { Key } from "@react-types/shared";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useKey, useOutsideClickRef } from "rooks";
import type { UserDto } from "@bespeak/apollo";
import { useGetEducationalProgramsForApp } from "@/services";
import { useProgramStore } from "@/store/preferences";
import Button from "@/components/base/atoms/Button";
import { CheckIcon, ChevronDownIcon } from "@/components/base/icons";

type NavigationProgramDropdownProps = {
    user: Partial<UserDto>;
};

const NavigationProgramDropdown = ({
    user,
}: NavigationProgramDropdownProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { educationalPrograms } = useGetEducationalProgramsForApp();

    const navigate = useNavigate();
    const location = useLocation();
    const programStore = useProgramStore();
    const storedProgramId = (programStore.value || "").toString();

    const handleSelectionChange = (programId: Key) => {
        if (!user) return;

        setDropdownOpen(false);

        programStore.setValue(programId.toString());

        if (location.pathname !== "/rapportage") {
            navigate(`/program/${programId}`);
        }
    };

    const handleCloseDropdown = () => {
        setDropdownOpen(false);
    };

    const [programDropdownRef] = useOutsideClickRef(handleCloseDropdown);

    useKey("Escape", () => {
        handleCloseDropdown();
    });

    useEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            dropdownRef.current.style.maxHeight = `calc(100vh - ${rect.top}px)`;
        }
    }, [dropdownOpen]);

    return (
        <div className="relative" ref={programDropdownRef}>
            <Button
                variant="neutralGhost"
                size="small"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="justify-between"
            >
                <span className="w-[15ch] overflow-hidden text-ellipsis whitespace-nowrap text-left">
                    {educationalPrograms?.find(
                        (program) => program.id === storedProgramId,
                    )?.name || educationalPrograms?.[0]?.name}
                </span>
                <ChevronDownIcon
                    className={clsx(
                        "h-5 w-5 transition-transform duration-300 ease-in-out will-change-transform",
                        {
                            "-scale-y-100": dropdownOpen,
                        },
                    )}
                />
            </Button>
            <div
                ref={dropdownRef}
                className={clsx(
                    "border-outline-300 absolute right-0 top-full z-10 mt-1.5 flex w-80 flex-col gap-1 overflow-y-auto overflow-x-hidden rounded-cardImage border bg-white p-1 shadow-md transition duration-300 ease-in-out",
                    {
                        "pointer-events-none translate-y-2 transform opacity-0":
                            !dropdownOpen,
                        "translate-y-0 transform opacity-100": dropdownOpen,
                    },
                )}
            >
                {educationalPrograms?.map((program) => (
                    <Button
                        key={program.id}
                        variant="primaryGhost"
                        size="small"
                        onClick={() => handleSelectionChange(program.id)}
                        className="w-full items-start justify-start text-left"
                    >
                        <div className="mr-auto">{program.name}</div>
                        {program.id === storedProgramId && (
                            <CheckIcon className="ml-auto h-5 w-5" />
                        )}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default NavigationProgramDropdown;
